const NAVIGATION_BAR_COOKIE = "ic_nav";
export default  {
    isNavOpen : true,
    showSearch: false,
    loadingProducts: false,
    showingDetails: [],
    isCaptchaLoaded: false,
    captchaToken: "",

    /**
     * Initiates the navBar state
     */
    ensureNavState() {
        let storedState = this.getCookie( NAVIGATION_BAR_COOKIE );
        // open nav by default
        if( !storedState ) this.openNav();
        this.isNavOpen =  storedState && ( storedState == "open" );
        if( !this.isNavOpen ) this.setClosedClass();
    },
    /**
     * Closes Nav
     */
    closeNav() {
        this.isNavOpen = false;
        this.setClosedClass();
        this.setCookie( NAVIGATION_BAR_COOKIE, "closed", 1 );
    },
    /**
     * Gets Cookie
     * @param {String} name - The name of cookie
     */
    getCookie( name ) {
        var nameEQ = name + "=";
        var ca = document.cookie.split( ";" );
        for ( var i = 0; i < ca.length; i++ ) {
            var c = ca[ i ];
            while ( c.charAt( 0 ) == " " ) c = c.substring( 1, c.length );
            if ( c.indexOf( nameEQ ) == 0 ) return c.substring( nameEQ.length, c.length );
        }
        return null;
    },
    /**
     * Opens Nav
     */
    openNav() {
        this.isNavOpen = true;
        this.unsetClosedClass();
        this.setCookie( NAVIGATION_BAR_COOKIE, "open", 1 );
    },
    /**
     * Sets Cookie
     * @param {String} name - The name of cookie
     * @param {String} value - The value of cookie
     * @param {Number} days - The number of days before exiration
     */
    setCookie( name, value, days ) {
        var expires = "";
        if ( days ) {
            var date = new Date();
            date.setTime( date.getTime() + ( days * 24 * 60 * 60 * 1000 ) );
            expires = "; expires=" + date.toUTCString();
        }
        document.cookie = name + "=" + ( value || "") + expires + "; SameSite=Strict; path=/";
    },
    /**
     * Adds class that minimizes nav, to the body
     */
    setClosedClass() {
        document.body.classList.add( "short" );
    },
    toggleSearch() {
        this.showSearch = !this.showSearch;
    },
    toggleShortMenu() {
        if ( this.isNavOpen  ) {
            this.closeNav();
        } else { 
            this.openNav();
        }
    },
    /**
     * Removes the class that minimizes nav, from the body
     */
    unsetClosedClass() {
        document.body.classList.remove( "short" );
    },
};