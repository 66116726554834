export default  {
    decorations: [],
    decorationModal: null,
    
    hasDecoration() {
        return this.decorations.length ? true : false;
    },
    resetDecorations() {
        this.decorations = [];
    }
};