import $ from "jquery";
import Panzoom from "@panzoom/panzoom";
import Dropzone from "dropzone";
import signupForm from "./components/signupForm.js";
import PrimaryStore from "./stores/primary-store.js";
import ProductDecorationStore from "./stores/product-decoration-store.js";

window.$ = window.jQuery = $;
window.Panzoom = Panzoom;
window.Dropzone = Dropzone;

const tooltipTriggerList = document.querySelectorAll( "[data-bs-toggle=\"tooltip\"]" );
const tooltipList = [ ...tooltipTriggerList ].map( tooltipTriggerEl => new bootstrap.Tooltip( tooltipTriggerEl ) );

document.addEventListener( "alpine:init", () => {
    Alpine.data( "signupForm", signupForm );
    Alpine.store( "primary", PrimaryStore );
    Alpine.store( "productDecoration", ProductDecorationStore );
} );

document.addEventListener( "DOMContentLoaded", ( event ) => {
    window._cartPreview = new bootstrap.Offcanvas( "#cartPreView"  );
});