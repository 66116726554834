export default () => ( {
    showPass: false,
    showConfirmPass: false,
    
    init() {
        this.$watch( "$store.primary.captchaToken", ( value ) => { 
            if( value != "" ) {
                this.$wire.validateCaptcha( value );
            } else {
                this.$wire.invalidateCaptcha();
            }
            
        } );
    },
    toggleShowConfirmPass() {
        this.showConfirmPass = !this.showConfirmPass;
    },
    toggleShowPass() {
        this.showPass = !this.showPass;
    },
    /**
     * Checks if the password matches the confirmed password.
     */
    validateMatch() {
        var passEl = document.getElementById( "password" );
        var confirmEl = document.getElementById( "confirmPassword" );
        var confirmMsgEl = document.getElementById( "confirmPasswordFeedback" );

        if( passEl.value !==  confirmEl.value ) {
            passEl.classList.add( "is-invalid" );
            confirmEl.classList.add( "is-invalid" );
            confirmMsgEl.classList.add( "invalid-feedback" );
            confirmMsgEl.innerHTML = "Passwords do not match";
        } else {
            passEl.classList.remove( "is-invalid" );
            confirmEl.classList.remove( "is-invalid" );
            confirmMsgEl.classList.remove( "invalid-feedback" );
            confirmMsgEl.innerHTML = "";
        }
    }

} );